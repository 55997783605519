"use client";

import styles from "@/common/partials/SurveyContent/SurveyContent.module.scss";
import SurveyWidget from "@/common/partials/SurveyContent/SurveyWidget";

type Props = {
  title?: string;
  bigText?: string;
};

const SurveyContent: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div className={styles.survey}>
        <div className="container">
          <div className={styles.surveyContent}>
            <h2>
              <span>{props.title || "簡単1分アンケート"}</span>
            </h2>
            <div className={styles.description}>
              <p>アンケートの回答は必ず開発チームが目を通します。</p>
            </div>
            <div className={styles.contentWrap}>
              <SurveyWidget campaign="above_qa_content"></SurveyWidget>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyContent;
